import { DAY } from "../../../cloud/js/classes/TimeInterval";

/**
 * @param {*} value
 * @returns {boolean}
 */
function isEmpty (value) {
  if (Array.isArray(value) && !value.length) {
    return true;
  }

  if (typeof value === 'object' && value !== null && !Object.keys(value).length) {
    return true;
  }

  return !value && value !== false && value !== 0;
}

/**
 * @param {string} str
 * @returns {string|*}
 */
function capitalize (str) {
  if (!str) {
    return str;
  }

  if (typeof _ === 'object' && typeof _.capitalize === 'function') {
    return _.capitalize(str);
  }

  return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
}

/**
 * @param {string} unit
 * @param {boolean} plural
 * @param {boolean} translate
 * @return {string}
 */
function makeUnitLabel (unit, plural = false, translate = __) {
  const singular = unit.toLowerCase();
  const label = plural ? `${singular}s` : singular;

  return typeof translate === 'function' ? translate(label) : label;
}

/**
 * @param {boolean} cut
 * @param {{frequency_number: number, frequency_unit: string}} frequency
 * @param {boolean} translate
 * @returns {string}
 */
function makeIntervalLabel (frequency, cut = true, translate = __) {
  const plural = frequency.frequency_number > 1;
  
  if (cut && !plural) {
    let unit;
    if (frequency.frequency_unit.toUpperCase() === DAY) {
      unit = 'daily';
    } else {
      unit = `${makeUnitLabel(frequency.frequency_unit, false, false)}ly`;
    }

    return typeof translate === 'function' ? translate(unit) : unit;
  } else {
    return `${frequency.frequency_number} ${makeUnitLabel(frequency.frequency_unit, plural, translate)}`;
  }
}

/**
 * @param {number} from
 * @param {number} to
 * @returns {number[]}
 */
function makeSerialArray (from, to) {
  const arr = [];
  for (let i = from; i <= to; i++) {
    arr.push(i);
  }

  return arr;
}

/**
 * @param {Object} shop
 */
function initHotjar(shop) {
  if (typeof window.hj !== 'function') {
    return;
  }

  window.hj('identify', shop.domain, {
    App: 'RecurrinGO',
    Domain: shop.domain,
    'Installation date': shop.analytics?.last_install_at,
    'Store name': shop.name,
  });
}

/**
 * @param {string} str
 * @returns {string}
 */
function stripTags (str) {
  return str.replace(/(?<tag><(?<tagName>[^>]+)>)/igu, '');
}

/**
 * @param {string} src
 * @param {function} onload
 */
function loadScript (src, onload = null) {
  const existing = document.querySelector(`script[src="${src}"]`);
  if (existing) {
    onload();
    return;
  }

  const script = document.createElement('script');
  script.src = src;

  if (typeof onload === 'function') {
    script.onload = onload;
  }

  document.head.appendChild(script);
}

/**
 * @param {string} name
 * @returns {string|null}
 */
function getCookie (name) {
  const matches = document.cookie.match(new RegExp(
    // eslint-disable-next-line prefer-named-capture-group
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/gu, '\\$1') + "=([^;]*)",
    'u',
  ));

  return matches ? decodeURIComponent(matches[1]) : null;
}

/**
 * @param {Object} item
 * @returns {string|*}
 */
function getFormattedProductTitle (item) {
  const DEFAULT_VARIANT_TITLES = ['Default Title', '-'];
  if (item?.variant?.title && item.variant.title.length > 0 && !DEFAULT_VARIANT_TITLES.includes(item.variant.title)) {
    return `${item.product?.title} - ${item?.variant?.title}`;
  }

  return item.product?.title;
}

/**
 * @param {string} string
 * @returns {*}
 */
function escape (string) {
  const htmlEscapes = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
  };

  return string.replace(/[&<>"']/ug, (match) => htmlEscapes[match]);
}

/**
 *
 */
function scrollToError () {
  const target = document.querySelector('.Polaris-TextField--error, .Polaris-Labelled__Error');
  if (!target) {
    return;
  }

  target.scrollIntoView({block: "center", behavior: 'smooth'});
}

/**
 * @param {string} unit
 * @param {int} number
 * @param {boolean} plural
 * @param {boolean} translate
 * @return {string}
 */
function makeBoxSizeLabel (unit, number, plural = false, translate = __) {
  const singular = unit.toLowerCase();
  const label = plural ? `${singular}s` : singular;
  const sizeLabel = `Box with ${number} ${label}`;

  return typeof translate === 'function' ? translate(sizeLabel) : sizeLabel;
}

/**
 * @returns {boolean}
 */
function isDocumentVisible() {
  if (typeof document.mozHidden !== "undefined") {
    return !document.mozHidden;
  }

  if (typeof document.msHidden !== "undefined") {
    return !document.msHidden;
  }

  if (typeof document.webkitHidden !== "undefined") {
    return !document.webkitHidden;
  }

  return !document.hidden;
}

export {
  capitalize,
  isEmpty,
  makeIntervalLabel,
  makeSerialArray,
  makeUnitLabel,
  stripTags,
  loadScript,
  getCookie,
  getFormattedProductTitle,
  escape,
  scrollToError,
  makeBoxSizeLabel,
  initHotjar,
  isDocumentVisible,
};